import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import FormControl from '@mui/material/FormControl';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import PropTypes from 'prop-types';
import React from 'react';

import ScheduledDeliveryOptions from './ScheduledDeliveryOptions';

const MessageComposer = ({
    handleMessageSubmit,
    imagesList,
    messageDetails,
    setMessageDetails,
    isHidden,
}) => {
    const {
        messageContent,
        messageHeading,
        messageScheduleDate,
        messageSendType,
        messageURL,
        messageURLDescription,
        selectedImageID,
    } = messageDetails;

    const handleChange = (event) => {
        const {
            target: {
                name,
                value,
            },
        } = event;

        setMessageDetails({
            ...messageDetails,
            [name]: value,
        });
    };

    const handleImageSelectToggle = (imageID) => {
        const selectedImageIDValue = selectedImageID === imageID ? undefined : imageID;

        setMessageDetails({
            ...messageDetails,
            selectedImageID: selectedImageIDValue,
        });
    };

    const renderedImagesList = imagesList.map((imageDetails) => {
        const {
            imageID,
            imageTitle,
            imageURL,
        } = imageDetails;

        const isSelectedImage = imageID === selectedImageID;

        return (
            <ImageListItem>
                <Button
                    color="success"
                    key={imageID}
                    variant={isSelectedImage ? 'outlined' : 'text'}
                    onClick={() => handleImageSelectToggle(imageID)}
                    disabled={isHidden}
                >
                    <img
                        className="MuiImageListItem-img"
                        alt={imageTitle}
                        src={imageURL}
                        loading="lazy"
                    />
                </Button>
            </ImageListItem>
        );
    });

    return (
        <LocalizationProvider dateAdapter={AdapterLuxon}>
            <form onSubmit={handleMessageSubmit}>
                <FormControl
                    margin="normal"
                    fullWidth
                >
                    <Typography
                        component="h2"
                        my={2}
                    >
                        Schedule a new message to send
                    </Typography>
                    <ScheduledDeliveryOptions
                        setMessageSendType={(value) => handleChange({
                            target: {
                                name: 'messageSendType',
                                value,
                            },
                        })}
                        setScheduleDate={(value) => handleChange({
                            target: {
                                name: 'messageScheduleDate',
                                value,
                            },
                        })}
                        messageSendType={messageSendType}
                        scheduleDate={messageScheduleDate}
                        isHidden={isHidden}
                    />
                    <TextField
                        fullWidth
                        label="Enter notification message header"
                        margin="normal"
                        multiline
                        name="messageHeading"
                        onChange={handleChange}
                        required
                        value={messageHeading}
                        disabled={isHidden}
                    />
                    <TextField
                        fullWidth
                        label="Enter notification message contents"
                        margin="normal"
                        maxRows={4}
                        multiline
                        name="messageContent"
                        onChange={handleChange}
                        required
                        value={messageContent}
                        disabled={isHidden}
                    />
                    <Tooltip
                        arrow
                        disableFocusListener={!!renderedImagesList.length}
                        disableHoverListener={!!renderedImagesList.length}
                        disableInteractive={!!renderedImagesList.length}
                        disableTouchListener={!!renderedImagesList.length}
                        title="You currently have no available images in your gallery"
                    >
                        <TextField
                            disabled
                            fullWidth
                            slotProps={{
                                input: {
                                    startAdornment: <InputAdornment position="start">#</InputAdornment>,
                                },
                            }}
                            label="Include an image in your message (selected from gallery)"
                            margin="normal"
                            name="selectedImageID"
                            type="number"
                            value={selectedImageID || ''}
                        />
                    </Tooltip>
                    <TextField
                        fullWidth
                        label="Include a link in your message (optional)"
                        margin="normal"
                        name="messageURL"
                        onChange={handleChange}
                        type="url"
                        value={messageURL}
                        disabled={isHidden}
                    />
                    <TextField
                        fullWidth
                        label={`Text to display for message link${messageURL ? '' : ' (optional)'}`}
                        margin="normal"
                        name="messageURLDescription"
                        onChange={handleChange}
                        required={!!messageURL}
                        value={messageURLDescription}
                        disabled={isHidden}
                    />
                    {
                        !!renderedImagesList.length && (
                            <ImageList
                                sx={{
                                    height: '100%',
                                    maxHeight: '40vh',
                                    width: '100%',
                                }}
                                cols={3}
                            >
                                {renderedImagesList}
                            </ImageList>
                        )
                    }
                    <ButtonGroup sx={{ marginTop: '20px' }}>
                        <Button
                            type="submit"
                            variant="contained"
                            disabled={isHidden}
                        >
                            Send Message
                        </Button>
                    </ButtonGroup>
                </FormControl>
            </form>
        </LocalizationProvider>
    );
};

MessageComposer.displayName = 'MessageComposer';

MessageComposer.propTypes = {
    handleMessageSubmit: PropTypes.func.isRequired,
    imagesList: PropTypes.arrayOf(PropTypes.shape({
        imageID: PropTypes.number.isRequired,
        imageTitle: PropTypes.string.isRequired,
        imageURL: PropTypes.string.isRequired,
    })).isRequired,
    messageDetails: PropTypes.shape({
        messageContent: PropTypes.string.isRequired,
        messageHeading: PropTypes.string.isRequired,
        messageScheduleDate: PropTypes.string,
        messageSendType: PropTypes.string.isRequired,
        messageURL: PropTypes.string.isRequired,
        messageURLDescription: PropTypes.string.isRequired,
        selectedImageID: PropTypes.number,
    }).isRequired,
    setMessageDetails: PropTypes.func.isRequired,
    isHidden: PropTypes.bool,
};

MessageComposer.defaultProps = {
    isHidden: false,
};

export default MessageComposer;
