import { DateTime } from 'luxon';

// https://v8.dev/features/intl-pluralrules#ordinal-numbers
const PLURAL_RULES = new Intl.PluralRules('en-US', {
    type: 'ordinal',
});

const SUFFIXES = new Map([
    ['one', 'st'],
    ['two', 'nd'],
    ['few', 'rd'],
    ['other', 'th'],
]);

const getOrdinalSuffix = (value) => {
    const rule = PLURAL_RULES.select(value);
    return SUFFIXES.get(rule);
};

const isValidDateString = (dateStr) => {
    if (!dateStr) {
        return false;
    }

    return !Number.isNaN(Date.parse(dateStr));
};

// Strips the time component from a date string and returns 'YYYY-MM-DD'.
const stripTime = (dateStr) => dateStr.substring(0, dateStr.indexOf('T'));

// Returns a date object for the given date string, at 00:00:00 on the given day in the local timezone.
const getLocalDateZeroHours = (dateStr) => {
    const date = new Date(stripTime(dateStr));
    return DateTime.fromJSDate(date).plus({ minutes: date.getTimezoneOffset() }).toJSDate();
};

const getDisplayDate = (date, delim = '/', endsWithYear = true) => {
    if (!date) {
        return '';
    }

    const dateStr = date.toString();

    let formattedDate = stripTime(dateStr);
    const firstIndex = formattedDate.indexOf('-');
    if (endsWithYear) {
        formattedDate = `${formattedDate.substring(firstIndex + 1, formattedDate.length)
        }-${formattedDate.substring(0, firstIndex)}`;
    }

    if (delim !== '-') {
        return formattedDate.replaceAll('-', delim);
    }

    return formattedDate;
};

const getDisplayTime = (date) => {
    if (!date) {
        return '';
    }

    const dateStr = date.toString();

    let formattedTime = dateStr.substring(dateStr.indexOf('T') + 1, dateStr.length);
    formattedTime = `${formattedTime.substring(0, formattedTime.lastIndexOf(':'))} UTC`;

    return formattedTime;
};

const getDisplayDateTime = (timestamp) => {
    if (!timestamp) {
        return '';
    }

    const generatedDate = new Date(timestamp);

    return generatedDate.toLocaleString('en-US', {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        hour12: true,
    });
};

const getFormattedTime = (date, time) => {
    const utcDateTimeString = `${date.split('T')[0]}T${time}Z`;
    const localDateTime = DateTime
        .fromISO(utcDateTimeString, { zone: 'utc' })
        .setZone('local');

    return localDateTime.toFormat('hh:mm a');
};

const getHoursMinutes = (date) => {
    if (!date) {
        return null;
    }
    // Handle JS Date
    if (date instanceof Date) {
        return DateTime.fromJSDate(date).toFormat('HH:mm:ss');
    }
    // Handle ISO Date
    if (typeof date === 'string' && DateTime.fromISO(date).isValid) {
        return DateTime.fromISO(date).toFormat('HH:mm:ss');
    }
    // Return null if invalid
    return null;
};

/**
 * This function will take string with format 'HH:mm:ss` to convert UTC date object
*/
const getDateObjFromHhMmSsString = (timeString) => {
    const [hours, minutes, seconds] = timeString.split(':');
    const date = new Date();
    date.setHours(hours); // Set the hours, using implicit type coercion
    date.setMinutes(minutes); // can pass Number or String - doesn't really matter
    date.setSeconds(seconds);
    return date;
};

const getUtcDateTime = (date) => (date ? date.toISOString().slice(0, 19).replace('T', ' ') : null);

const getDateTimeStr = (date) => {
    if (!date) {
        return null;
    }
    // Handle JS Date
    if (date instanceof Date) {
        return DateTime.fromJSDate(date).toFormat('yyyy-MM-dd HH:mm:ss');
    }
    // Handle ISO Date
    if (typeof date === 'string' && DateTime.fromISO(date).isValid) {
        return DateTime.fromISO(date).toFormat('yyyy-MM-dd HH:mm:ss');
    }
    // Return null if invalid
    return null;
};

const getPrettyPublishDate = (currentPublication) => {
    const dateObj = new Date(currentPublication.publishDate);
    const displayDate = dateObj.getDate() + 1; // extra day for 1 day offset (see POL-1369)
    const updatedDate = new Date(dateObj);
    updatedDate.setDate(displayDate);

    return DateTime.fromJSDate(updatedDate).toFormat('ccc, LLL d');
};

const sortPublicationByPublishDate = (publications, limit = 12) => {
    const publishBy = 'publishDate';
    return publications.filter((p) => !!p[publishBy])
        .sort((a, b) => Date.parse(b[publishBy]) - Date.parse(a[publishBy]))
        .slice(0, limit);
};

const sanitizeLuxonDateValue = (dateValue) => {
    switch (true) {
        case !dateValue:
            return null;
        case DateTime.isDateTime(dateValue):
            return dateValue;
        case dateValue instanceof Date:
            return DateTime.fromJSDate(dateValue);
        case typeof dateValue === 'string' && DateTime.fromISO(dateValue).isValid:
            return DateTime.fromISO(dateValue);
        case typeof dateValue === 'number' && Number.isFinite(dateValue):
            return DateTime.fromMillis(dateValue);
        default:
            return null;
    }
};

export {
    getOrdinalSuffix,
    getDisplayDate,
    getDisplayTime,
    isValidDateString,
    stripTime,
    getLocalDateZeroHours,
    getHoursMinutes,
    getUtcDateTime,
    getDateObjFromHhMmSsString,
    getPrettyPublishDate,
    getDateTimeStr,
    sortPublicationByPublishDate,
    getDisplayDateTime,
    getFormattedTime,
    sanitizeLuxonDateValue,
};
