import React from 'react';
import PropTypes from 'prop-types';
import MaterialCheckbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Tooltip from '@mui/material/Tooltip';
import { Field } from 'formik';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from '@fortawesome/pro-regular-svg-icons';
import styles from './Checkbox.scss';

const Checkbox = ({
    label,
    marginBottom,
    disabled,
    classes,
    name,
    onChange,
    errors,
    touched,
    tooltip,
}) => (
    <div className={classNames(styles.container, classes.root)}>
        <div
            className={classNames({
                [styles.marginBottom]: marginBottom,
            })}
        >
            <FormControlLabel
                control={(
                    <Field
                        component={MaterialCheckbox}
                        type="checkbox"
                        name={name}
                        onChange={onChange}
                        disabled={disabled}
                        label={{ label: 'Exact' }}
                    />
                )}
                label={(
                    <>
                        {label}
                        {tooltip && (
                            <Tooltip
                                placement="left-start"
                                title={tooltip}
                                className={classNames(styles.tooltip, styles.infoIcon)}
                            >
                                <FontAwesomeIcon icon={faCircleInfo} />
                            </Tooltip>
                        )}
                    </>
                )}
            />
            {errors && touched ? (
                <div className={styles.errors}>
                    {errors}
                </div>
            ) : null}
        </div>
    </div>
);

Checkbox.propTypes = {
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    disabled: PropTypes.bool,
    classes: PropTypes.shape({
        root: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
    }),
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    marginBottom: PropTypes.bool,
    errors: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string]),
    touched: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.bool]),
    tooltip: PropTypes.string,
};

Checkbox.defaultProps = {
    classes: {
        input: null,
        label: null,
    },
    label: null,
    disabled: false,
    onChange: {},
    errors: null,
    touched: {},
    marginBottom: true,
    tooltip: null,
};

export default Checkbox;
