import * as React from 'react';
import PropTypes from 'prop-types';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Grid2';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

import { sanitizeLuxonDateValue } from '../../utils/date';

export const MINIMUM_FUTURE_TIME_MS = 900_000;

const now = new Date();
export const minimumDateTime = new Date(now.getTime() + MINIMUM_FUTURE_TIME_MS);

const ScheduledDeliveryOptions = ({
    messageSendType,
    scheduleDate,
    setMessageSendType,
    setScheduleDate,
    isHidden,
}) => (
    <Grid
        container
        spacing={2}
    >
        <Grid
            size={{
                xs: 12,
                md: 4,
            }}
        >
            <FormLabel>Message Scheduling</FormLabel>
            <RadioGroup
                name="send-message-button-group"
                row
                value={messageSendType}
            >
                <FormControlLabel
                    value="now"
                    control={<Radio />}
                    label="Send Now"
                    onClick={() => setMessageSendType('now')}
                    disabled={isHidden}
                />
                <FormControlLabel
                    value="later"
                    control={<Radio />}
                    label="Send Later"
                    onClick={() => setMessageSendType('later')}
                    disabled={isHidden}
                />
            </RadioGroup>
        </Grid>
        <Grid
            mb={1}
            size={{
                xs: 12,
                md: 8,
            }}
        >
            <DateTimePicker
                disabled={messageSendType !== 'later' || isHidden}
                disablePast
                label="Select schedule date and time"
                minDateTime={minimumDateTime}
                onChange={setScheduleDate}
                slotProps={{
                    textField: {
                        fullWidth: true,
                    },
                }}
                value={sanitizeLuxonDateValue(scheduleDate)}
            />
        </Grid>
    </Grid>
);

ScheduledDeliveryOptions.propTypes = {
    messageSendType: PropTypes.string.isRequired,
    setMessageSendType: PropTypes.func.isRequired,
    scheduleDate: PropTypes.instanceOf(Date).isRequired,
    setScheduleDate: PropTypes.func.isRequired,
    isHidden: PropTypes.bool,
};

ScheduledDeliveryOptions.defaultProps = {
    isHidden: false,
};

export default ScheduledDeliveryOptions;
