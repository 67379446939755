import {
    faBan,
    faCheck,
    faPencilMechanical,
    faPlus,
} from '@fortawesome/pro-light-svg-icons';
import { faTrash } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import React, {
    useState,
    useContext,
} from 'react';
import * as yup from 'yup';

import {
    COLORS,
    POL_THEME,
    SIZES,
    ThemeContext,
} from '../../../constants/theme';
import {
    getFormattedTime,
    sanitizeLuxonDateValue,
} from '../../../utils/date';

import Button from '../Button';

import styles from './EventManagement.scss';

const EventManagement = ({
    events,
    addEventCallback,
    removeEventCallback,
    isSponsor,
    isHidden,
}) => {
    const themeContext = useContext(ThemeContext);
    const TODAY = new Date();
    TODAY.setHours(0, 0, 0, 0);

    const [editingEvent, setEditingEvent] = useState(null);
    const [deletingEvent, setDeletingEvent] = useState(null);
    const [sendEmail, setSendEmail] = useState(false);
    const [saving, setSaving] = useState(false);

    // Define the validation schema using yup
    const validationSchema = yup.object({
        title: yup.string('Enter a title').required('Title is required'),
        event_date: yup.date().required('Date is required'),
        all_day: yup.bool(),
        start_time: yup.date().when('all_day', (allDay, schema) => (allDay ? schema.notRequired() : schema.required('Start time is required'))),
        end_time: yup.date().when('all_day', (allDay, schema) => (allDay ? schema.notRequired() : schema.required('End time is required'))),
        description: yup.string().nullable(),
    });

    const formik = useFormik({
        initialValues: {
            title: '',
            event_date: new Date(),
            all_day: false,
            start_time: null,
            end_time: null,
            description: '',
        },
        validationSchema,
        onSubmit: (e) => {
            e.preventDefault();
        },
    });

    const styleProps = {
        tableHeading: {
            fontSize: '1.3rem',
            fontWeight: 600,
            paddingBottom: '0.5rem',
            textTransform: 'uppercase',
        },
    };

    const dateFormatter = new Intl.DateTimeFormat();

    const toFormattedDate = (date) => dateFormatter.format(new Date(date)) || '';

    const toBeginningOfDay = (date) => {
        const d = new Date(date);
        d.setHours(0, 0, 0, 0);
        return d.getTime();
    };

    const handleAddEvent = async () => {
        formik.setSubmitting(true);
        await formik.validateForm();
        if (!formik.isValid) {
            return;
        }
        if (!formik.values.all_day) {
            if (!formik.values.start_time || !formik.values.end_time) {
                return;
            }
            if (formik.values.start_time > formik.values.end_time) {
                return;
            }
        }

        let sendData = { ...formik.values };

        // Convert to corrected date/time values
        sendData.event_date = new Date(sendData.event_date).toISOString().slice(0, 19).replace('T', ' ');
        if (!sendData.all_day) {
            sendData.start_time = new Date(sendData.start_time).toISOString().split('T')[1].slice(0, 8);
            sendData.end_time = new Date(sendData.end_time).toISOString().split('T')[1].slice(0, 8);
        } else {
            sendData.start_time = null;
            sendData.end_time = null;
        }

        if (!editingEvent.id) {
            sendData = {
                ...sendData,
                send_email: sendEmail,
                time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                is_pol: themeContext === POL_THEME,
            };
        } else {
            sendData.id = editingEvent.id;
        }
        setSaving(true);

        try {
            const success = await addEventCallback(sendData);
            if (success) {
                setEditingEvent(null);
                setSendEmail(false);
                formik.resetForm();
            }
        } catch {
            setSaving(false);
        }
        setSaving(false);
    };

    const handleEditEvent = (event) => {
        const eventDate = new Date(event.event_date);
        const startDate = event.start_time ? new Date(`${event.event_date.split('T')[0]}T${event.start_time}Z`) : null;
        const endDate = event.end_time ? new Date(`${event.event_date.split('T')[0]}T${event.end_time}Z`) : null;

        formik.setValues({
            title: event.title,
            event_date: eventDate,
            all_day: !!event.all_day,
            start_time: startDate,
            end_time: endDate,
            description: event.description,
        });

        setEditingEvent(event);
    };

    const handleRemoveEvent = async () => {
        setSaving(true);
        const success = await removeEventCallback(deletingEvent.id);
        if (success) {
            setDeletingEvent(null);
        }
        setSaving(false);
    };

    const handleChange = (field, value) => {
        formik.setFieldValue(field, value);
        formik.setSubmitting(false);
    };

    const handleCancel = () => {
        formik.resetForm();
        setEditingEvent(null);
    };

    const getEndTimeHelperText = () => {
        if (formik.isSubmitting) {
            if (!formik.values.all_day) {
                if (!formik.values.end_time) {
                    return 'End time is required';
                }
                if (formik.values.start_time > formik.values.end_time) {
                    return 'End time must be later than start time';
                }
            }
        }
        return '';
    };

    return (!editingEvent) ? (
        <>
            <div className={styles.menuBar}>
                <Button
                    size={SIZES.large}
                    onClick={() => { setEditingEvent({ event_date: new Date() }); }}
                    disabled={isHidden}
                >
                    <FontAwesomeIcon
                        icon={faPlus}
                        style={{ marginRight: '0.5rem' }}
                    />
                    Create Event
                </Button>
            </div>
            {(events.length === 0) ? (<h3 className={styles.noEvents}>No Events to Display</h3>) : (
                <TableContainer>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell sx={styleProps.tableHeading}>
                                    Title
                                </TableCell>
                                <TableCell sx={styleProps.tableHeading}>
                                    Date
                                </TableCell>
                                <TableCell sx={styleProps.tableHeading}>
                                    Start Time
                                </TableCell>
                                <TableCell sx={styleProps.tableHeading}>
                                    End Time
                                </TableCell>
                                <TableCell />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {events
                                .filter((event) => event.deleted_at === null && new Date(event.event_date) >= TODAY)
                                .map((event) => (
                                    <TableRow key={event.id}>
                                        <TableCell>
                                            {`${event.title || ''}`}
                                        </TableCell>
                                        <TableCell>
                                            {toFormattedDate(event.event_date)}
                                        </TableCell>
                                        <TableCell>
                                            {event.all_day ? 'All Day' : getFormattedTime(event.event_date, event.start_time)}
                                        </TableCell>
                                        <TableCell>
                                            {event.all_day ? 'All Day' : getFormattedTime(event.event_date, event.end_time)}
                                        </TableCell>
                                        <TableCell
                                            align="right"
                                            sx={{
                                                width: 250,
                                            }}
                                        >
                                            {(!deletingEvent || deletingEvent !== event)
                                                && (
                                                    <>
                                                        <Button
                                                            size={SIZES.medium}
                                                            color={COLORS.flat}
                                                            onClick={() => {
                                                                handleEditEvent(event);
                                                            }}
                                                            disabled={isHidden}
                                                        >
                                                            <FontAwesomeIcon
                                                                icon={faPencilMechanical}
                                                                style={{ marginRight: '0.75rem' }}
                                                            />
                                                            Edit
                                                        </Button>
                                                        <Button
                                                            size={SIZES.medium}
                                                            color={COLORS.flat}
                                                            leftMargin={false}
                                                            fullWidth={false}
                                                            onClick={() => setDeletingEvent(event)}
                                                            disabled={isHidden}
                                                        >
                                                            <FontAwesomeIcon
                                                                icon={faTrash}
                                                                style={{ marginRight: '0.75rem' }}
                                                            />
                                                            Delete
                                                        </Button>
                                                    </>
                                                )}
                                            {(deletingEvent && deletingEvent === event)
                                                && (
                                                    <>
                                                        <Button
                                                            size={SIZES.medium}
                                                            color={COLORS.flat}
                                                            otherClasses={styles.confirm}
                                                            leftMargin={false}
                                                            fullWidth={false}
                                                            disabled={saving || isHidden}
                                                            onClick={() => handleRemoveEvent()}
                                                        >
                                                            <FontAwesomeIcon
                                                                icon={faCheck}
                                                                style={{ marginRight: '0.5rem' }}
                                                            />
                                                            Confirm
                                                        </Button>
                                                        <Button
                                                            size={SIZES.medium}
                                                            color={COLORS.flat}
                                                            otherClasses={styles.cancel}
                                                            leftMargin={false}
                                                            fullWidth={false}
                                                            disabled={saving || isHidden}
                                                            onClick={() => setDeletingEvent(null)}
                                                        >
                                                            <FontAwesomeIcon
                                                                icon={faBan}
                                                                style={{ marginRight: '0.5rem' }}
                                                            />
                                                            Cancel
                                                        </Button>
                                                    </>
                                                )}
                                        </TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
        </>
    ) : (
        <div className={styles.editEventContainer}>
            <h2 className={styles.header}>
                {editingEvent.id ? 'Update' : 'Create'}
                {' '}
                Event
            </h2>
            <div className={styles.formRow}>
                <FormControl
                    sx={{
                        width: '70ch',
                    }}
                >
                    <TextField
                        id="title"
                        name="title"
                        label="Event Title"
                        value={formik.values.title}
                        onChange={(e) => handleChange('title', e.target.value)}
                        onBlur={formik.handleBlur}
                        slotProps={{
                            input: {
                                maxLength: 45,
                            },
                        }}
                        helperText={formik.isSubmitting && formik.errors.title}
                        error={formik.errors.title && formik.isSubmitting}
                        required
                        disabled={isHidden}
                    />
                    <FormHelperText>
                        {formik.values.title.length}
                        /45
                    </FormHelperText>
                </FormControl>
            </div>
            <div className={styles.formRow}>
                <FormControl
                    sx={{
                        width: '45ch',
                    }}
                >
                    <LocalizationProvider dateAdapter={AdapterLuxon}>
                        <DesktopDatePicker
                            inputFormat="MM/dd/yyyy"
                            id="event-date-id"
                            label="Event Date"
                            value={sanitizeLuxonDateValue(formik.values.event_date)}
                            disablePast
                            onChange={(value) => handleChange('event_date', value)}
                            onBlur={formik.handleBlur}
                            slotProps={{
                                textField: {
                                    helperText: formik.isSubmitting && formik.errors.event_date,
                                    required: true,
                                    error: !!formik.errors.event_date && !!formik.touched.event_date,
                                    fullWidth: true,
                                },
                            }}
                            disabled={isHidden}
                        />
                    </LocalizationProvider>
                </FormControl>
            </div>
            <div className={styles.formRow}>
                <FormControlLabel
                    sx={{
                        fontSize: '1.2rem',
                    }}
                    control={(
                        <Checkbox
                            sx={{
                                paddingRight: '0px',
                            }}
                            checked={formik.values.all_day}
                            onChange={(e) => handleChange('all_day', e.target.checked)}
                            disabled={isHidden}
                        />
                    )}
                    label="All Day Event"
                />
            </div>
            <div className={styles.formRow}>
                <LocalizationProvider dateAdapter={AdapterLuxon}>
                    <Stack
                        spacing={2}
                        direction="row"
                        sx={{
                            justifyContent: 'start',
                        }}
                    >
                        <TimePicker
                            label="Start Time"
                            value={sanitizeLuxonDateValue(formik.values.start_time)}
                            onChange={(value) => handleChange('start_time', value)}
                            onBlur={formik.handleBlur}
                            slotProps={{
                                textField: {
                                    error: formik.isSubmitting && (!formik.values.all_day && !formik.values.start_time),
                                    helperText: formik.isSubmitting && (!formik.values.all_day && !formik.values.start_time) && 'Start time is required',
                                },
                            }}
                            disabled={formik.values.all_day || isHidden}
                            disablePast={toBeginningOfDay(formik.values.event_date) === TODAY.getTime()}
                        />
                        <TimePicker
                            label="End Time"
                            value={sanitizeLuxonDateValue(formik.values.end_time)}
                            minTime={formik.values.start_time}
                            onChange={(value) => handleChange('end_time', value)}
                            onBlur={formik.handleBlur}
                            slotProps={{
                                textField: {
                                    error: formik.isSubmitting && (!formik.values.all_day && (!formik.values.end_time || (formik.values.start_time > formik.values.end_time))),
                                    helperText: getEndTimeHelperText(),
                                },
                            }}
                            disabled={formik.values.all_day || isHidden}
                            disablePast={toBeginningOfDay(formik.values.event_date) === TODAY.getTime()}
                        />
                    </Stack>
                </LocalizationProvider>
            </div>
            <div className={styles.formRow}>
                <FormControl
                    sx={{
                        width: '70ch',
                        marginTop: '2rem',
                    }}
                >
                    <TextField
                        id="description"
                        name="description"
                        label="Event Description - Optional"
                        multiline
                        minRows={2}
                        maxRows={20}
                        value={formik.values.description}
                        onChange={(e) => handleChange('description', e.target.value)}
                        onBlur={formik.handleBlur}
                        slotProps={{
                            input: {
                                maxLength: 6500,
                            },
                        }}
                        helperText={formik.isSubmitting && formik.errors.description}
                        error={formik.errors.description && formik.isSubmitting}
                        disabled={isHidden}
                    />
                    <FormHelperText>
                        {formik.values.description?.length || 0}
                        /6500
                    </FormHelperText>
                </FormControl>
            </div>
            { (!editingEvent.id && !isSponsor) && (
                <div className={styles.formRow}>
                    <FormControlLabel
                        sx={{
                            fontSize: '1.2rem',
                        }}
                        control={(
                            <Checkbox
                                sx={{ paddingRight: '0px' }}
                                checked={sendEmail}
                                onChange={(e) => setSendEmail(e.target.checked)}
                                disabled={isHidden}
                            />
                        )}
                        label="Email subscribers about this upcoming event"
                    />
                </div>
            )}
            <Stack
                className={styles.actionsBar}
                spacing={2}
                direction="row"
                sx={{
                    justifyContent: 'start',
                }}
            >
                <Button
                    size={SIZES.medium}
                    color={COLORS.flat}
                    leftMargin={false}
                    fullWidth={false}
                    onClick={() => {
                        handleCancel(null);
                    }}
                    disabled={saving || isHidden}
                >
                    Cancel
                </Button>
                <Button
                    size={SIZES.medium}
                    leftMargin={false}
                    fullWidth={false}
                    onClick={() => {
                        handleAddEvent();
                    }}
                    disabled={saving || isHidden}
                >
                    {editingEvent.id ? 'Update' : 'Create'}
                    {' '}
                    Event
                </Button>
            </Stack>
        </div>
    );
};

EventManagement.propTypes = {
    events: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        title: PropTypes.string,
        description: PropTypes.string,
        event_date: PropTypes.string,
        start_time: PropTypes.string,
        end_time: PropTypes.string,
        all_day: PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.bool,
        ]),
        created_at: PropTypes.string,
        updated_at: PropTypes.string,
        deleted_at: PropTypes.string,
    })).isRequired,
    addEventCallback: PropTypes.func.isRequired,
    removeEventCallback: PropTypes.func.isRequired,
    isSponsor: PropTypes.bool,
    isHidden: PropTypes.bool,
};

EventManagement.defaultProps = {
    isSponsor: false,
    isHidden: false,
};

export default EventManagement;
